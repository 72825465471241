import React from 'react';
import { Link } from 'gatsby';
import logoFooter from '../assets/images/logo-footer.png';

export default function Footer() {
  return (
    <footer>
      <div className="content-mw-1100">
        <div>
          <img src={logoFooter} alt="Blues Creamery" />
        </div>
        <div className="footer-menu">
          <ul>
            <li>
              <Link to="/cheeses">Order Online</Link>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
            <li>
              <Link to="/suppliers">Our Suppliers</Link>
            </li>
            <li>
              <Link to="/#about-us">About Us</Link>
            </li>
          </ul>
        </div>
        <div>
          <p>
            The Blues Creamery LTD
            <br />
            Newtown
            <br />
            Glen of Aherlow
            <br />
            Co. Tipperary
            <br />
            E34 EH39
            <br />
            thebluescreamery.sales@gmail.com
          </p>
        </div>
      </div>
    </footer>
  );
}
