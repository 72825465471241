// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cheeses-full-list-js": () => import("./../../../src/pages/cheeses-full-list.js" /* webpackChunkName: "component---src-pages-cheeses-full-list-js" */),
  "component---src-pages-cheeses-js": () => import("./../../../src/pages/cheeses.js" /* webpackChunkName: "component---src-pages-cheeses-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-suppliers-js": () => import("./../../../src/pages/suppliers.js" /* webpackChunkName: "component---src-pages-suppliers-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/Product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

