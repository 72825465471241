import React from 'react';
import styled from 'styled-components';
import 'normalize.css';
import GlobalStyles from '../styles/GlobalStyles';
// import Typography from '../styles/Typography';
import Footer from './Footer';
import { Link } from 'gatsby';
import Nav from './Nav';
import Logo from './Logo';
import headerChevron from '../assets/images/header-chevron-bg.png';
import MobileMenu from './MobileMenu';
import CookieConsent from 'react-cookie-consent';

const SiteBorderStyles = styled.div`
  margin-top: 0;
  padding-top: 0;
`;

const ContentStyles = styled.div`
  background: white;
  padding-top: 0;
`;

const Header = styled.div`
  background-color: #e8e8e8;
  background: url(${headerChevron});
`;

const HeaderContent = styled.div`
  display: grid;
  grid-template-columns: minmax(100px, 242px) auto;
  max-width: 1100px;
  margin: 0 auto;
`;

const MainContent = styled.div`
  min-height: 400px;
`;

const Copyright = styled.div`
  background-color: var(--blue);
  padding: 10px;
  color: white;
  font-size: 1.5rem;
  padding-bottom: 20px;
`;

export default function Layout({ children }) {
  return (
    <>
      <GlobalStyles />
      {/* <Typography /> */}
      <SiteBorderStyles>
        <ContentStyles>
          <Header>
            <MobileMenu />
            <HeaderContent>
              <Logo />
              <Nav />
            </HeaderContent>
          </Header>
          <MainContent>{children}</MainContent>
          <Footer />
          <Copyright>
            <div className="content-mw-1100">
              &copy; Blues Creamery 2020. All Rights Reserved
            </div>
          </Copyright>
        </ContentStyles>
      </SiteBorderStyles>
      {/* <CookieConsent
        enableDeclineButton
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="blues-creamery-gdpr-google-analytics"
        style={{ background: "#2B373B", fontSize: '14px', lineHeight: '150%', padding: '15px' }}
        buttonStyle={{ color: "#4e503b", fontSize: "11px !important" }}
        expires={150}
      >
        <div style={{ maxWidth: "1100px", margin: "0 auto" }}>
        By clicking “Accept”, you agree to the storing of cookies on your device to improve site navigation and assist us in analyzing site usage statistics:{" "}
          <Link
            to="/cookie-policy"
            style={{ color: "white", textDecoration: "underline" }}
          >
            Cookie Policy
          </Link>
        </div>
      </CookieConsent> */}
    </>
  );
}
