import React from 'react';
import { Link } from 'gatsby';
import { slide as Menu } from 'react-burger-menu';
import myBasket from '../assets/images/btn-my-basket.png';

export default (props) => (
  // Pass on our props
  <Menu right>
    <a className="menu-item" href="/">
      Home
    </a>
    <a className="menu-item" href="/cheeses">
      Cheeses
    </a>
    <a className="menu-item" href="/suppliers">
      Suppliers
    </a>
    <a className="menu-item" href="/contact">
      Contact
    </a>
    <a href="">
      <img style={{ border: 'none' }} src={myBasket} alt="" />
    </a>
    <a
      className="menu-item"
      href="tel:+353873914935"
      style={{ color: '#ffff00' }}
    >
      Phone: (087) 391 4935
    </a>
  </Menu>
);
