import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import myBasket from '../assets/images/btn-my-basket.png';
import currentPage from '../assets/images/current-page-yellow-flat.png';

const NavStyles = styled.nav`
  height: 80px;
  font-family: AlataRegular;
  font-weight: bold;
  /* transform: translateY(-25%); */

  ul {
    /* height: 10rem; */
    margin: 0;
    padding: 0;
    text-align: center;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 2rem;
    align-items: center;
    height: 70px;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      display: table;
      width: 100%;
      padding-left: 180px;
      li {
        display: table-cell;
        width: 100px;
      }
    }

    @media (max-width: 680px) {
      display: none;
    }
  }
  li:first-child {
    position: relative;
  }

  /* @media (max-width: 680px) {
    li:not(:last-child) {
      display: none;
    }
  } */

  a {
    font-size: 1.5rem;
    text-decoration: none;
    display: block;
    height: 40px;
    color: white;
    &:hover {
      color: #ffff00;
    }
    &[aria-current='page'] {
      color: #ffff00;
      background: url(${currentPage});
      background-position: bottom center;
      background-repeat: no-repeat;
    }
  }
  img {
    max-width: 135px;
    margin-top: -9px;
  }

  input[type='checkbox'] {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  label {
    display: none;
    cursor: pointer;
    user-select: none;
  }
`;

export default function Nav() {
  return (
    <NavStyles>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/cheeses">Cheeses</Link>
        </li>
        <li>
          <Link to="/suppliers">Suppliers</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
        <li>
          <Link to="/basket">
            <img src={myBasket} alt="" className="snipcart-checkout" />
          </Link>
        </li>
      </ul>
    </NavStyles>
  );
}
