import { createGlobalStyle } from 'styled-components';
import footerBg from '../assets/images/bkg-footer.jpg';
import chevronGrungeBorderTop from '../assets/images/chevron-grunge-border-top.png';
import cowLabel from '../assets/images/bkg-label-cow-v2.png';
import sheepLabel from '../assets/images/bkg-label-sheep-v2.png';
import goatLabel from '../assets/images/bkg-label-goat-v2.png';
import homeProductsGroupBg from '../assets/images/bkg-home-products-group.jpg';

const GlobalStyles = createGlobalStyle`
  :root {
    --red: #FF4949;
    --black: #2E2E2E;
    --yellow: #ffc600;
    --white: #fff;
    --grey: #efefef;
    --blue: #015cab;
  }
  html {
    font-size: 10px;
  }

  body {
    font-size: 2rem;
    font-family: AlataRegular;
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  button {
    background: var(--red);
    color: white;
    border: 0;
    padding: 0.6rem 1rem;
    border-radius: 2px;
    cursor: pointer;
    --cast: 2px;
    box-shadow: var(--cast) var(--cast) 0 var(--grey);
    text-shadow: 0.5px 0.5px 0 rgba(0,0,0,0.2);
    transition: all 0.2s;
    &:hover {
      --cast: 4px;
    }
  }

  .home-range {
    @media (max-width: 680px) {
      grid-template-columns: 1fr 1fr !important;
    }
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  hr {
    border: 0;
    height: 8px;
    background-size: 1500px;
  }

  img {
    max-width: 100%;
  }

  .tilt {
    transform: rotate(-2deg);
    position: relative;
    display: inline-block;
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    background: #353535;
    font-size: 3rem;
    color: #ccc;
    box-shadow: rgba(3, 8, 20, 0.1) 0px 0 0.15rem 0.5rem, rgba(2, 8, 20, 0.1) 0px 0.075rem 0.175rem;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    transition: all 500ms;
    overflow: hidden;
    position: relative;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .card p {
    font-size: 1.7rem;

    @media (max-width: 680px) {
      padding: 10px;
    }
  }

  .card p span {
    display: block;
    font-size: 2rem;
    color: #ffcc00;
  }

  .card:hover {
    box-shadow: rgba(2, 8, 20, 0.1) 0px 0.35em 1.175em, rgba(2, 8, 20, 0.08) 0px 0.175em 0.5em;
    transform: translateY(-3px) scale(1.1);
  }
  

  .basic-grid {
    display: grid;
    gap: 1rem;

    /* 1 too skinny, too much code */
    grid-template-columns: 1fr 1fr 1fr 1fr;

    /* 2 cleaner code */
    grid-template-columns:repeat(12);
  }

  .three-col-grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;

    @media (max-width: 680px) {
      grid-template-columns: 1fr 1fr;
      gap: 0.5rem;
      padding: 10px;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      display: table;
      width: 100%;
      .card {
        display: table-cell;
        width: 30%;
      }
    }
    
  }

  .four-col-grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @media (max-width: 680px) {
      grid-template-columns: 1fr;
    }
  }

  .content-mw-1100 {
    max-width: 1100px;
    margin: 0 auto;
  }

  .content-mw-980 {
    max-width: 980px;
    margin: 0 auto;
  }

  footer {
  background: url(${chevronGrungeBorderTop}), url(${footerBg});
    background-position: top center, bottom center;
    background-repeat: no-repeat;
    min-height: 200px;
    padding: 20px;
    margin-top: 40px;
    padding-top: 40px;
  }

  footer > div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    @media (max-width: 680px) {
      display: block;
      text-align: center;

      .footer-menu { 
        display: none;
      }

    }
  }

  footer img {
    max-width: 160px;
  }

  footer ul, 
  footer p {
    font-size: 1.5rem;
  }

  footer ul{
    margin-left: 0;
    padding-left: 0;
    list-style-type: none;
  }

  h3 {
    color: var(--blue);
    font-family: BrandFont;
    text-transform: uppercase;
    padding-bottom: 20px;
    text-align: center;
  }

  h5 {
    color: var(--blue);
    font-size: 2rem;
  }

  .process-desc {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 1.6rem;
    font-weight: normal;
    margin-bottom: 30px;
  }

  .our-suppliers,
  .team-kala {
    padding-top: 50px;
  }

  .grid-no-scale {
    grid-gap: 2rem;
  }

  .grid-no-scale p {
    width: 100%;
  }

  .grid-no-scale .card {
    background: white;
    color: #353535;
    border: solid 0px #dfdfdf;
    border-radius: 0;
    box-shadow: none !important;
  }

  .grid-no-scale .card:hover {
    transform: translateY(0px) scale(1);
  }

  .home-products-group {
    background: url(${homeProductsGroupBg});
    background-size: cover;
    background-position: center center;
    padding: 50px;

    h3 {
      color: white;
    }
  }

  .home-products-group .basic-grid{
    padding-bottom: 40px;
  }

  .home-products-group .card {
    background-color: white;
    color: #2E2E2E;
  }



  @media (min-width: 700px) {
    .bm-menu-wrap,
    .bm-overlay,
    .bm-burger-button {
      display: none;
    }
  }

  /* Individual item */
  .bm-item {
    display: inline-block;

    /* Our sidebar item styling */
    text-decoration: none;
    margin-bottom: 10px;
    color: #d1d1d1;
    transition: color 0.2s;
  }

  /* Change color on hover */
  .bm-item:hover {
    color: white;
  }

  /********* BEGIN MOBILE STYLES ******/
  /* The rest copied directly from react-burger-menu docs */

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 46px;
    height: 40px;
    right: 36px;
    top: 20px;

    button {
      box-shadow: none;
      --cast: none;
    }
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: white;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 44px;
    width: 44pvgx;
  }

  #react-burger-cross-btn {
    width: 44px;
    height: 44px;
    --cast: none;

  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /* General sidebar styles */
  .bm-menu {
    background: var(--blue);
    padding: 2.5em 1.8em 0;
    font-size: 1.25em;
    font-family: brandFont;
    overflow: hidden;
    text-transform: uppercase;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: var(--blue);
  }

  /********* END MOBILE STYLES ****/

  .cheeses-range .card{
    text-align: left;
    font-size: 1.5rem;
    background-color: #666;
    border-radius: 4px;
    p {
      margin-top: 0;
    }
    .price-per-kg {
      font-size: 1.5rem !important;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;

      button {
        margin-left: 10px;
        --cast: none;
        background-color: #ea5139;
      }
      span {
        display: block;
        margin-right: 1rem;
        font-size: 1.3rem;
        padding-left: 10px;
        padding-top: 10px;
      }
    }

    a {
      display: block;
      color: white;
      text-decoration: none;

      span {
        display: block;
        margin:10px;
      }
    }
  }

  .sidebar {
    @media (max-width: 680px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding: 5px;
      grid-gap: 0.5rem;

      label {
        margin-right: 0 !important;
      }
    }
  }

  .cheeses-range-cols {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 1rem;

    @media (max-width: 680px) {
      display: block;
    }

    label {
      display: block;
      padding-bottom: 10px;
      cursor: pointer;
      background-color: #dfdfdf;
      padding-left: 15px;
      margin-bottom: 10px;
      padding-top: 10px;
      color: white;
      margin-right: 20px;

      span {
        text-transform: uppercase;
        font-family: BrandFont;
        padding-left: 10px;
      }
    }

    .label-cow {
      background: var(--blue) url(${cowLabel});
      background-position: top -5px right;
      background-repeat: no-repeat;
    }
    .label-sheep {
      background: #dfdfdf url(${sheepLabel});
      background-position: top -5px right;
      background-repeat: no-repeat;
    }
    .label-goat {
      background: #dfdfdf url(${goatLabel});
      background-position: top -5px right;
      background-repeat: no-repeat;
    }
  }
  .intro-para {
    font-size: 2rem;
      span {
        display: block;
        color: black;
      }

      @media (max-width: 680px) {
        display: block;
        > div {
          padding-left: 15px;
        }
      }

      > div {
        margin-right: 30px;
      }

      img { max-width: 100%;}
    }


  
  .snipcart-modal__close-title {
    text-transform: uppercase !important;
    font-weight: bold !important;
    background-color: var(--blue) !important;
    font-weight: 1.7rem !important;
    height: 25px !important;
    color: white !important;
  }

  .snipcart-item-quantity__label {
    display: none !important;
  }

  .snipcart-item-line__container {
    padding: 8px !important;
    border-radius: 5px !important;
    margin-bottom: 0px !important;
    padding-bottom: 10px;
    border-bottom: dashed 1px #ccc !important;
  }

  .buy-now-link {
    background-color: #ea5139;
    color: white;
    border-radius: 5px;
    display: inline-block;
    padding: 5px 10px 10px 10px;
    margin-left: 10px;
    text-align: center;
  }

  .buy-now-link:disabled {
    cursor: arrow;
  }

`;

export default GlobalStyles;
