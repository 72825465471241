import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import bluesCreameryLogo from '../assets/images/tbc-logo.png';

const LogoStyles = styled.div`
  /* This value controls the entire size of the logo*/
  position: relative;

  img {
    position: absolute;
    max-width: 140px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.55);
  }
`;

export default function Logo() {
  return (
    <LogoStyles className="logo">
      <Link to="/">
        <img src={bluesCreameryLogo} alt="Blues Creamery" />
      </Link>
    </LogoStyles>
  );
}
